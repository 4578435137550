<template>
  <form action="." method="POST" @submit.prevent="submit">
    <!-- Access code field -->
    <div class="field mb-3">
      <label class="label has-text-primary">{{ $t("access_code.access_code_title") }}</label>
      <div class="control">
        <input class="input is-simple" type="password" :placeholder="$t('access_code.access_code_title')" v-model="accessCode" />
      </div>
      <template v-if="submitted">
        <p v-if="errorMessage" class="help is-danger">{{ errorMessage }}</p>
      </template>
    </div>

    <!-- Submit button -->
    <div class="field">
      <div class="control">
        <button class="button is-primary is-fullwidth" type="submit" :class="{ 'is-loading': showProgress }">
          {{ $t("auth.log_in") }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
  name: "AccessCodeForm",

  props: ["showProgress", "errorMessage"],

  data() {
    return {
      submitted: false,
      accessCode: "",
    };
  },

  watch: {
    accessCode(newVal) {
      this.$emit("clear-error");
    },
  },

  validations: {
    accessCode: {
      required,
    },
  },

  methods: {
    submit() {
      this.$v.$touch();
      this.$data.submitted = true;

      if (!this.$v.$invalid) {
        const {accessCode} = this.$data;
        this.$emit("submit", accessCode);
      }
    },
  },
};
</script>
